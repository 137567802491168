import React from 'react'
import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { siteTheme } from '../components/layout/theme'

import HomeLayout from '../components/layout/home/HomeLayout'
import InsuranceCard from '../components/layout/home/InsuranceCard'
import HoverImage from '../components/ui/HoverImage'

// import LogoChainAccelerator from '../images/clients/logo_ChainAccelerator.svg'
// import LogoSASMinimum from '../images/clients/logo_SASMinimum.png'
// import LogoArtmajeur from '../images/clients/logo_Artmajeur.png'
// import LogoYSYCloud from '../images/clients/logo_YSYCloud.png'
// import LogoAIG from '../images/partners/logo_AIG.svg'
// import LogoChubb from '../images/partners/logo_Chubb.svg'
// import LogoHISCOX from '../images/partners/logo_HISCOX.svg'
// import LogoMalakoffMederic from '../images/partners/logo_MalakoffMederic.png'

import ImgVague1 from '../images/vague_01_1.svg'
import ImgVague2 from '../images/vague_02_1.svg'
import ImgVague3 from '../images/vague_03.svg'
import ImgHero from '../images/illustration_01.svg'

import ImgIcRcPro from '../images/icons/ic_rc_pro.svg'
import ImgIcCyberRisques from '../images/icons/ic_cyber_risques.svg'
import ImgIcMultirisquePro from '../images/icons/ic_multirisque_pro.svg'
import ImgIcAssuranceDirigeant from '../images/icons/ic_assurance_dirigeant.svg'

// Customers
import ImgLogoHeraMiNb from '../images/logos/logo_hera_mi_nb.svg'
import ImgLogoHeraMiRvb from '../images/logos/logo_hera_mi_rvb.svg'
import ImgLogoYsyCloudNb from '../images/logos/logo_ysy_cloud_nb.svg'
import ImgLogoYsyCloudRvb from '../images/logos/logo_ysy_cloud_rvb.svg'
import ImgLogoFreeYourInboxNb from '../images/logos/logo_free_your_inbox_nb.svg'
import ImgLogoFreeYourInboxRvb from '../images/logos/logo_free_your_inbox_rvb.svg'
import ImgLogoCyphemeNb from '../images/logos/logo_cypheme_nb.svg'
import ImgLogoCyphemeRvb from '../images/logos/logo_cypheme_rvb.svg'
import ImgLogoSasMinimumNb from '../images/logos/logo_sas_minimum_nb.svg'
import ImgLogoSasMinimumRvb from '../images/logos/logo_sas_minimum_rvb.svg'

// Partners
import ImgLogoHiscoxNb from '../images/logos/logo_hiscox_nb.svg'
import ImgLogoHiscoxRvb from '../images/logos/logo_hiscox_rvb.svg'
import ImgLogoAigNb from '../images/logos/logo_aig_nb.svg'
import ImgLogoAigRvb from '../images/logos/logo_aig_rvb.svg'
import ImgLogoChubbNb from '../images/logos/logo_chubb_nb.svg'
import ImgLogoChubbRvb from '../images/logos/logo_chubb_rvb.svg'
import ImgLogoGeneraliNb from '../images/logos/logo_generali_nb.svg'
import ImgLogoGeneraliRvb from '../images/logos/logo_generali_rvb.svg'
import ImgLogoAxaNb from '../images/logos/logo_axa_nb.svg'
import ImgLogoAxaRvb from '../images/logos/logo_axa_rvb.svg'
import ImgLogoMalakoffMedericNb from '../images/logos/logo_malakoff_mederic_nb.svg'
import ImgLogoMalakoffMedericRvb from '../images/logos/logo_malakoff_mederic_rvb.svg'
// import ImgLogoGscNb from '../images/logos/logo_gsc_nb.svg'

const styles = theme => ({
  heroSection: {
    paddingTop: '150px',
    paddingBottom: '100px',
    // background: 'url(/images/Vague.svg) center bottom no-repeat, linear-gradient(65deg, #198CFF 0%, #01EEFD 100%)',
    // background: `url(${ImgVague}) center bottom no-repeat, linear-gradient(65deg, #198CFF 0%, #01EEFD 100%)`,
    background: `url(${ImgVague1}) center bottom no-repeat, ${siteTheme.gradient1}`,
    backgroundSize: 'contain'
    // position: 'relative'
  },
  heroContent: {
    width: 'auto',
    // minHeight: '550px',
    minHeight: '600px',
    padding: '0px',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
    // color: theme.palette.common.white
  },
  heroGrid: {
    flexGrow: 1
  },
  heroTitle: {
    fontSize: '46px',
    fontWeight: 500,
    lineHeight: '48px',
    color: siteTheme.color2,
    '& strong': {
      fontWeight: 'bold',
      color: siteTheme.white
    }
  },
  heroText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '28px',
    color: siteTheme.white,
    padding: `${theme.spacing.unit * 3}px 0px`
  },
  heroQuoteButton: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    color: siteTheme.color1,
    backgroundColor: siteTheme.white,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
    boxShadow: siteTheme.boxShadowNone,
    textTransform: 'none'
  },
  // heroList: {
  //   // Use image for '-' ?
  //   listStyleType: 'disc',
  //   paddingInlineStart: '1rem'
  // },
  // heroListItem: {
  //   '&::before': {
  //     content: '"- "'
  //   }
  // }
  heroIllustration: {
    width: '100%',
    maxWidth: '600px'
  },

  coversSection: {
    // marginTop: '16px',
    // paddingTop: `${theme.spacing.unit * 6}px`,
    paddingTop: '60px',
    paddingBottom: '200px',
    background: `url(${ImgVague2}) center bottom no-repeat, ${siteTheme.gradient2}`,
    backgroundSize: 'contain'
    // position: 'relative'
  },
  coversContent: {
    width: 'auto',
    minHeight: '250px',
    padding: '0px',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
    // color: theme.palette.common.white
  },
  coversGrid: {
    flexGrow: 1
  },
  coversTitle: {
    fontSize: '42px',
    fontWeight: 'bold',
    lineHeight: '44px',
    color: siteTheme.color1
  },
  coversText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    color: siteTheme.color8,
    padding: `${theme.spacing.unit * 3}px 0px`
  },
  coversButtonContainer: {
    paddingTop: '40px'
  },
  coversButton: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    color: siteTheme.white,
    backgroundColor: siteTheme.color3,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
    boxShadow: siteTheme.boxShadow4,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: siteTheme.color2
    }
  },

  customersSection: {
    // marginTop: '16px',
    paddingTop: '100px',
    paddingBottom: '50px',
    // background: `url(${ImgVague2}) center bottom no-repeat, ${siteTheme.gradient2}`,
    // backgroundSize: 'contain',
    backgroundColor: siteTheme.white
    // position: 'relative'
  },
  customersContent: {
    width: 'auto',
    // minHeight: '250px',
    padding: '0px',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
    // color: theme.palette.common.white
  },
  customersGrid: {
    flex: '1 1 auto',
    // flexGrow: 1,
    padding: '16px 24px'
  },
  customersTitle: {
    fontSize: '42px',
    fontWeight: 'bold',
    lineHeight: '44px',
    textAlign: 'center',
    color: siteTheme.color1
  },
  customersText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    textAlign: 'center',
    color: siteTheme.color8,
    padding: `${theme.spacing.unit * 3}px 0px`
  },

  partnersSection: {
    marginTop: '100px',
    // border: 'solid 1px red',
    // paddingTop: '100px',
    paddingBottom: '100px',
    background: `url(${ImgVague3}) center top no-repeat, ${siteTheme.color5}`,
    backgroundSize: 'contain'
    // backgroundColor: siteTheme.white
    // position: 'relative'
  },
  partnersContent: {
    width: 'auto',
    // minHeight: '250px',
    padding: '80px 24px',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    // color: theme.palette.common.white
    backgroundColor: siteTheme.white,
    boxShadow: siteTheme.boxShadow1
  },
  partnersGrid: {
    flex: '1 1 auto',
    // flexGrow: 1,
    padding: '16px 24px'
  },
  partnersTitle: {
    fontSize: '42px',
    fontWeight: 'bold',
    lineHeight: '44px',
    textAlign: 'center',
    color: siteTheme.color1
  },
  partnersText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    textAlign: 'center',
    color: siteTheme.color8,
    padding: `${theme.spacing.unit * 3}px 0px`
  }

})

function index (props) {
  const {
    classes
  } = props

  return (
    <HomeLayout>
      <section className={classes.heroSection}>
        <div className={classes.heroContent}>
          <Grid container
            direction='row'
            justify='center'
            alignItems='stretch'
            spacing={16}
            className={classes.heroGrid}
          >
            <Grid item xs={12} sm={6}>
              <Typography component='h1' className={classes.heroTitle}>
                <strong>L'assurance professionnelle</strong><br />
                sur-mesure et sans perte de temps.
              </Typography>
              <Typography component='p' className={classes.heroText}>
                - Sélectionnez des couvertures adaptées à votre activité.<br />
                - Comparez les devis des assureurs en toute transparence.<br />
                - Souscrivez simplement et rapidement.
              </Typography>
              <Button
                component={Link} to='/devis'
                variant='contained'
                className={classes.heroQuoteButton}
              >
                Obtenir mes tarifs
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img src={ImgHero} className={classes.heroIllustration} />
            </Grid>
          </Grid>
        </div>
        {/* <img src={ImgVague} style={{ width: '100%', position: 'absolute', bottom: '0px' }} /> */}
      </section>
      <section className={classes.coversSection}>
        <div className={classes.coversContent}>
          <Grid container
            direction='row'
            justify='center'
            alignItems='stretch'
            spacing={40}
            className={classes.coversGrid}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Typography component='h2' className={classes.coversTitle}>
                Des couvertures dédiées aux entrepreneurs
              </Typography>
              <Typography component='p' className={classes.coversText}>
                Vous souhaitez assurer votre activité, vos locaux, vos salariés.<br />
                Phileass vous propose une solution adaptée, simple et transparente.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Grid container
                direction='row'
                justify='flex-start'
                alignItems='stretch'
                spacing={40}
                // className={classes.heroGrid}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <InsuranceCard
                    label='RC Pro'
                    description='Couvre les conséquences financières des dommages causés aux tiers.'
                    path='/solutions-d-assurance/rc-pro'
                    imgLogo={ImgIcRcPro}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <InsuranceCard
                    label='Cyber risques'
                    description='Couvre les atteintes aux données et aux systèmes d’information.'
                    path='/solutions-d-assurance/cyber-risques'
                    imgLogo={ImgIcCyberRisques}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <InsuranceCard
                    label='Multirisque Pro'
                    description='Couvre les biens, les locaux et l’exploitation.'
                    path='/solutions-d-assurance/multirisque-pro'
                    imgLogo={ImgIcMultirisquePro}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <InsuranceCard
                    label='Assurance dirigeant'
                    description='Couvre la responsabilité civile personnelle des dirigeants.'
                    path='/solutions-d-assurance/assurance-dirigeant'
                    imgLogo={ImgIcAssuranceDirigeant}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.coversButtonContainer}>
              <Button
                component={Link} to='/solutions-d-assurance'
                variant='contained'
                className={classes.coversButton}
              >
                Découvrir toutes nos couvertures
              </Button>
            </div>
          </Grid>
        </div>
      </section>
      <section className={classes.customersSection}>
        <div className={classes.customersContent}>
          <Typography component='h2' className={classes.customersTitle}>
            Ils nous font confiance
          </Typography>
          <Typography component='p' className={classes.customersText}>
            Plusieurs centaines de clients nous recommandent.
          </Typography>
          <Grid container
            direction='row'
            justify='center'
            // alignItems='center'
            alignItems='stretch'
            alignContent='stretch'
            spacing={40}
            className={classes.customersGrid}
          >
            <Grid item xs={6} sm={4} md={2}>
              <a href='https://www.linkedin.com/company/hera-mi/'>
                <HoverImage image={ImgLogoHeraMiNb} hoverImage={ImgLogoHeraMiRvb} altText='Hera Mi' />
              </a>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <a href='https://ysycloud.fr/'>
                <HoverImage image={ImgLogoYsyCloudNb} hoverImage={ImgLogoYsyCloudRvb} altText='YSY Cloud' />
              </a>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <a href='https://freeyourinbox.com/fr'>
                <HoverImage image={ImgLogoFreeYourInboxNb} hoverImage={ImgLogoFreeYourInboxRvb} altText='Free Your Inbox' />
              </a>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <a href='http://cypheme.com'>
                <HoverImage image={ImgLogoCyphemeNb} hoverImage={ImgLogoCyphemeRvb} altText='Cypheme' />
              </a>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <a href='https://www.sasminimum.com/'>
                <HoverImage image={ImgLogoSasMinimumNb} hoverImage={ImgLogoSasMinimumRvb} altText='SAS Minimum' />
              </a>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className={classes.partnersSection}>
        <div className={classes.partnersContent}>
          <Typography component='h2' className={classes.partnersTitle}>
            Nos partenaires
          </Typography>
          <Typography component='p' className={classes.partnersText}>
            Nous travaillons avec des assureurs de premier plan.
          </Typography>
          <Grid container
            direction='row'
            justify='center'
            // alignItems='center'
            alignItems='stretch'
            alignContent='stretch'
            spacing={40}
            className={classes.partnersGrid}
          >
            <Grid item xs={6} sm={4} md={2}>
              <Link to='/partenaires'>
                <HoverImage image={ImgLogoHiscoxNb} hoverImage={ImgLogoHiscoxRvb} altText='Hiscox' />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link to='/partenaires'>
                <HoverImage image={ImgLogoAigNb} hoverImage={ImgLogoAigRvb} altText='AIG' />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link to='/partenaires'>
                <HoverImage image={ImgLogoChubbNb} hoverImage={ImgLogoChubbRvb} altText='Chubb' />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link to='/partenaires'>
                <HoverImage image={ImgLogoGeneraliNb} hoverImage={ImgLogoGeneraliRvb} altText='Generali' />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link to='/partenaires'>
                <HoverImage image={ImgLogoAxaNb} hoverImage={ImgLogoAxaRvb} altText='AXA' />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link to='/partenaires'>
                <HoverImage image={ImgLogoMalakoffMedericNb} hoverImage={ImgLogoMalakoffMedericRvb} altText='Malakoff Médéric' />
              </Link>
            </Grid>
          </Grid>
        </div>
      </section>
    </HomeLayout>
  )
}

export default withStyles(styles)(index)

// export const query = graphql`
//   query {
//     imgWaiter: file(relativePath: { eq: "waiter.png" }) {
//       childImageSharp {
//         fixed(height: 270) {
//           ...GatsbyImageSharpFixed_withWebp
//         }
//       }
//     }
//   }
// `
