import React from 'react'
import { Link } from 'gatsby'

import { withStyles } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import classNames from 'classnames'

import { siteTheme } from '../theme'
import LogoPhileass from '../../../images/logo_phileass_03.svg'
import LogoPhileassTop from '../../../images/logo_phileass_04.svg'

import MegaMenuMetiers from '../MegaMenuMetiers'
import MegaMenuAssurances from '../MegaMenuAssurances'
import HamburgerMenu from '../../ui/HamburgerMenu'
import HamburgerMenuAssurances from '../HamburgerMenuAssurances'
import HamburgerMenuMetiers from '../HamburgerMenuMetiers'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  appBar: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    height: '90px',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: 'transparent',
    boxShadow: siteTheme.boxShadowNone,
    transition: 'all 0.5s ease',
    '&.scroll': {
      boxShadow: siteTheme.boxShadow4,
      backgroundColor: siteTheme.white
    }
  },
  toolbar: {
    padding: '0px'
  },
  spaceGrow: {
    flexGrow: 1
  },
  spaceFix: {
    width: '0.8rem'
  },
  appBarLogo: {
    height: '30px'
  },

  menuToolbarDesktop: {
    padding: '0px'
  },
  megaMenuDesktop: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '21px',
    textTransform: 'none',
    color: siteTheme.white,
    boxShadow: siteTheme.boxShadowNone,
    backgroundColor: 'transparent',
    borderRadius: 0,
    // margin: theme.spacing.unit,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    // '&:hover': {
    //   // color: theme.palette.common.white,
    //   // backgroundColor: 'rgba(255,255,255,0.25)'
    //   backgroundColor: 'transparent'
    // },
    '&.selected': {
      color: siteTheme.color3,
      fontWeight: 'bold'
    },
    '&.scroll': {
      color: siteTheme.color6
    },
    '&.scroll.selected': {
      color: siteTheme.color3,
      fontWeight: 'bold'
    }
  },
  menuButtonDesktop: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '21px',
    textTransform: 'none',
    borderRadius: 0,
    // margin: theme.spacing.unit,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: siteTheme.white,
    '&.selected': {
      color: siteTheme.color3,
      fontWeight: 'bold'
    },
    '&.scroll': {
      color: siteTheme.color6
    },
    '&.scroll.selected': {
      color: siteTheme.color3,
      fontWeight: 'bold'
    }
  },

  hamburgerMenuMobile: {
    display: 'inline-flex'
  },
  quoteButtonMobile: {
    fontSize: '0.95rem',
    // fontSize: '1.1rem',
    fontWeight: 600,
    textTransform: 'none',
    color: siteTheme.white,
    boxShadow: siteTheme.boxShadowNone,
    backgroundColor: theme.palette.primary.main
  }
})

class HomeHeader extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      scrolled: false
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll (e) {
    if (window.scrollY < 20) {
      this.setState({
        scrolled: false
      })
    } else {
      this.setState({
        scrolled: true
      })
    }
  }

  render () {
    const {
      classes,
      activeSection
    } = this.props
    const scrolled = this.state.scrolled

    return (
      <React.Fragment>
        <AppBar id='appbar'
          className={classNames(classes.appBar, { scroll: scrolled })}
        >
          <div className={classes.layout}>
            <Toolbar className={classes.toolbar}>
              <Link to='/'>
                <img
                  src={scrolled ? LogoPhileass : LogoPhileassTop}
                  alt='Phileass'
                  className={classes.appBarLogo}
                />
              </Link>
              <div className={classes.spaceGrow} />

              <Hidden smDown implementation='css'>
                <Toolbar className={classes.menuToolbarDesktop}>
                  <MegaMenuMetiers
                    activeSection={activeSection}
                    className={classNames(classes.megaMenuDesktop, { scroll: scrolled })}
                  />
                  {/* <div className={classes.spaceFix} /> */}
                  <MegaMenuAssurances
                    activeSection={activeSection}
                    className={classNames(classes.megaMenuDesktop, { scroll: scrolled })}
                  />
                  {/* <div className={classes.spaceFix} /> */}
                  <Button href='http://blog.phileass.com'
                    className={classNames(classes.menuButtonDesktop, { scroll: scrolled })}
                  >
                  Blog
                  </Button>
                  {/* <div className={classes.spaceFix} /> */}
                  <Button component={Link} to='/qui-sommes-nous'
                    className={classNames(classes.menuButtonDesktop, { scroll: scrolled }, { selected: activeSection === 'apropos' })}
                  >
                  A propos
                  </Button>
                  {/* <div className={classes.spaceFix} /> */}
                  <Button component={Link} to='/contact'
                    className={classNames(classes.menuButtonDesktop, { scroll: scrolled }, { selected: activeSection === 'contact' })}
                  >
                  Contact
                  </Button>
                  {/* <div className={classes.spaceFix} />
                  <Button component={Link} to='/devis'
                    color='primary' variant='contained'
                    className={
                      classNames(classes.quoteButton,
                        (scrolled
                          ? classes.quoteButtonScrolled
                          : classes.quoteButtonTop)
                      )}>Obtenir mon tarif</Button>
                  <div className={classes.spaceFix} /> */}
                  {/* <Button color='primary' variant='outlined'
                    className={
                      classNames(classes.loginButton,
                        (scrolled
                          ? classes.loginButtonScrolled
                          : classes.loginButtonTop)
                      )}>Me connecter</Button> */}
                </Toolbar>
              </Hidden>

              <Hidden mdUp implementation='css'>
                <HamburgerMenu
                  className={classes.hamburgerMenuMobile}
                  headerContent={
                    <Button
                      component={Link} to='/devis'
                      color='primary' variant='contained'
                      className={classes.quoteButtonMobile}
                    >
                      Obtenir mes tarifs
                    </Button>
                  }
                >
                  <List>
                    {/* <ListItem button
                      component={Link} to='/devis'>
                      <Button
                        // component={Link} to='/devis'
                        color='primary' variant='contained'
                        className={classes.quoteButtonMobile}
                      >
                        Obtenir mes tarifs
                      </Button>
                    </ListItem> */}
                    <HamburgerMenuMetiers />
                    <HamburgerMenuAssurances />
                    <ListItem button
                      component='a' href='http://blog.phileass.com'
                    >
                      <ListItemText primary='Blog' />
                    </ListItem>
                    <ListItem button
                      component={Link} to='/qui-sommes-nous'
                    >
                      <ListItemText primary='A propos' />
                    </ListItem>
                    <ListItem button
                      component={Link} to='/contact'
                    >
                      <ListItemText primary='Contact' />
                    </ListItem>
                  </List>
                </HamburgerMenu>
              </Hidden>

            </Toolbar>
          </div>
        </AppBar>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(HomeHeader)
