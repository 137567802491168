import React from 'react'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    // maxWidth: '400px'
    // '&:hover $overlay': {
    //   opacity: 1
    // },
    // '&:hover $image': {
    //   opacity: 0
    // }
    '&:hover $overlay': {
      [theme.breakpoints.up('md')]: {
        opacity: 1
      }
    },
    '&:hover $image': {
      [theme.breakpoints.up('md')]: {
        opacity: 0
      }
    }
  },
  image: {
    display: 'block',
    // width: '100%',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
    opacity: 0,
    transition: '0.3s ease',
    [theme.breakpoints.up('md')]: {
      opacity: 1
    }
  },
  overlay: {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    opacity: 1,
    transition: '0.3s ease',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('md')]: {
      opacity: 0
    }
  },
  hoverImage: {
    display: 'block',
    // width: '100%',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto'
  }
})

class HoverImage extends React.Component {
  render () {
    const {
      image,
      hoverImage,
      altText,
      classes
    } = this.props

    return (
      <div className={classes.container}>
        <img src={image} alt={altText} className={classes.image} />
        <div className={classes.overlay}>
          <img src={hoverImage} alt={altText} className={classes.hoverImage} />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(HoverImage)
