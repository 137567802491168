import React from 'react'

import { Link } from 'gatsby'

import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

import { siteTheme } from '../../layout/theme'

const styles = theme => ({
  card: {
    height: '100%',
    boxShadow: siteTheme.boxShadow1,
    backgroundColor: siteTheme.white,
    padding: '36px',
    paddingBottom: '12px' // 36 - 24 (last-item css)
  },
  cardContent: {
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'flex-start'
    padding: '0px'
  },
  logoContainer: {
    // flex: '1 0 auto',
    // display: 'block',
    height: '54px'
  },
  itemLogo: {
    width: 'auto',
    height: '50px'
  },
  textContainer: {
    // flex: '1 1 auto',
    // display: 'block'
  },
  itemTitle: {
    color: siteTheme.color6,
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '29px',
    marginTop: '16px',
    marginBottom: '16px'
  },
  itemText: {
    color: siteTheme.color8,
    fontSize: '16px',
    lineHeight: '28px'
  },
  linkContainer: {
    // TODO
    marginTop: '16px'
  },
  linkButton: {
    fontSize: '14px',
    // fontWeight: 'bold',
    lineHeight: '16px',
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
    // padding: 0,
    // textTransform: 'uppercase'
  }
})

class InsuranceCard extends React.Component {
  render () {
    const {
      label,
      description,
      path,
      imgLogo,
      classes
    } = this.props

    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.logoContainer}>
            {
              imgLogo &&
                <img className={classes.itemLogo}
                  src={imgLogo}
                  alt={label}
                />
            }
          </div>
          <div className={classes.textContainer}>
            <Typography className={classes.itemTitle}>
              {label}
            </Typography>
            <Typography className={classes.itemText}>
              {description}
            </Typography>
          </div>
          <div className={classes.linkContainer}>
            <Button
              className={classes.linkButton}
              component={Link}
              to={path}
            >
              En savoir plus
            </Button>
          </div>
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(InsuranceCard)
